import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
    <Layout>
        <SEO title="UI"/>

        <div className="full-wrapper">
            <h2>Добро пожаловать на страницу UI</h2>
            <p>Здесь вы найдёте для себя стандартное отображение базовых элементов, таких как абзацы, нумерованные и ненумерованные списки, таблицы, изображения в тексте, кнопки и формы. Приятного просмотра.</p>

            <h1>Заголовок первого уровня</h1>
            <p>Lorem ipsum dolor sit amet,
                <b>consectetur adipiscing elit</b>. Praesent tellus tortor, efficitur eget placerat sed, efficitur sed arcu. Aliquam sodales gravida dapibus. Proin vulputate sed ipsum vel sollicitudin. Vivamus in ultrices purus, at mollis leo. Morbi efficitur est mauris, sit amet hendrerit lectus mollis nec. Sed rutrum mollis tincidunt. Maecenas posuere vehicula aliquam. Nunc feugiat iaculis nisi ut vestibulum. Proin nisi erat, fringilla sed ex quis, vestibulum efficitur mauris. Fusce dictum magna ac lectus ultrices, quis scelerisque risus scelerisque. Integer convallis dictum posuere. Mauris mattis ullamcorper libero, a blandit urna pellentesque nec. Integer faucibus metus nec urna fermentum, ac hendrerit mi elementum.
            </p>


            <h2>Заголовок второго уровня</h2>
            <p>Integer at congue nisl, sed semper erat. <a href="#!"
                                                           target="_blank">In fringilla lorem odio</a>, nec mattis dolor bibendum a. In hac habitasse platea dictumst. Aliquam auctor accumsan gravida. Maecenas congue suscipit mauris, sed dignissim lorem mollis in. Duis sit amet neque et massa molestie lobortis. Phasellus sed lorem leo. Morbi porta sapien consequat porta sollicitudin. Vestibulum hendrerit dui et facilisis congue. Morbi venenatis mattis lacus ac ullamcorper. In tincidunt dapibus pulvinar. Vestibulum lobortis, tellus vel aliquam lobortis, eros magna lobortis dolor, ac interdum turpis orci vitae massa.
            </p>


            <h3>Заголовок третьего уровня</h3>
            <p><i>Vestibulum commodo varius dui. Fusce tempor accumsan eros vitae lobortis.</i> Aliquam varius dui in massa finibus sodales. Nunc nulla turpis, efficitur nec efficitur ac, <a href="#!"
                                                                                                                                                                                               target="_blank">vestibulum</a> non ex. Morbi porta sem eget risus commodo accumsan. Maecenas gravida dolor cursus, cursus lorem nec, imperdiet nulla. Pellentesque non fermentum felis.
            </p>


            <h4>Заголовок четвертого уровня</h4>
            <p>Donec vel metus quam. Pellentesque sed velit ut massa tristique elementum vitae vitae velit. Aliquam quam magna, dapibus eu euismod vitae, imperdiet vitae nulla. Suspendisse sodales felis nibh, at vulputate massa sodales non. Duis eu sapien massa. Proin in mauris sit amet purus pretium finibus. Suspendisse feugiat urna augue, sit amet posuere ante laoreet volutpat. Mauris euismod augue vel diam tincidunt, non ultricies tortor scelerisque. In rutrum justo quis lobortis molestie. Cras rhoncus erat nunc, in laoreet justo maximus quis. Aliquam tempus, purus sit amet maximus cursus, erat massa dictum augue, id bibendum libero turpis id metus.</p>

            <br/>
            <br/>


            <h2>Ненумерованный список</h2>
            <ul>
                <li>Lorem ipsum dolor sit amet, consectetur <b>adipiscing</b> elit.</li>
                <li>Praesent <strong>tellus tortor</strong>, efficitur eget placerat sed, efficitur sed arcu.</li>
                <li>Sed rutrum mollis tincidunt. <a
                    href="#!">Maecenas posuere</a> vehicula aliquam. Nunc feugiat iaculis nisi ut vestibulum. Proin nisi erat, fringilla sed ex quis, vestibulum efficitur mauris.
                </li>
            </ul>

            <br/>
            <br/>


            <h2>Нумерованный список</h2>
            <ol>
                <li>Lorem ipsum dolor sit amet, consectetur <b>adipiscing</b> elit.</li>
                <li>Praesent <i>tellus tortor</i>, efficitur eget placerat sed, efficitur sed arcu.</li>
                <li>Sed rutrum mollis tincidunt. <a
                    href="#!">Maecenas posuere</a> vehicula aliquam. Nunc feugiat iaculis nisi ut vestibulum. Proin nisi erat, fringilla sed ex quis, vestibulum efficitur mauris.
                </li>
                <li>In fringilla lorem odio, nec mattis dolor bibendum a.</li>
                <li>Vestibulum lobortis, tellus vel aliquam lobortis, eros magna lobortis dolor, ac interdum turpis orci vitae massa.</li>
            </ol>
            <br/>
            <br/>


            <h2>Таблица</h2>
            <table>
                <thead>
                <tr>
                    <th>Пн</th>
                    <th>Вт</th>
                    <th>Ср</th>
                    <th>Чт</th>
                    <th>Пт</th>
                    <th>Сб</th>
                    <th>Вс</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:30-16:30</td>
                    <td colSpan="2">выходной</td>
                </tr>
                <tr>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:30-16:30</td>
                    <td colSpan="2">выходной</td>
                </tr>
                <tr>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:00-17:30</td>
                    <td>9:30-16:30</td>
                    <td colSpan="2">выходной</td>
                </tr>
                </tbody>
            </table>

            <br/>
            <br/>

            <h2>Кнопки</h2>

            <h4>Обычная кнопка</h4>
            <div className="row">
                <div className="col-12 col-md-4">
                    <h5>Обычное состояние</h5>
                    <button className="button">
                        <span>Отправить</span>
                    </button>
                    <br/>
                </div>
                <div className="col-12 col-md-4">
                    <h5>disabled</h5>

                    <button disabled className="button">
                        <span>Отправить</span>
                    </button>
                    <br/>
                </div>
            </div>

            <br/>
            <br/>

            <h4>Кнопка - flat</h4>
            <div className="row">
                <div className="col-12 col-md-4">
                    <h5>Обычное состояние</h5>
                    <button className="button button--flat">
                        <span>Отправить</span>
                    </button>
                    <br/>
                </div>
                <div className="col-12 col-md-4">
                    <h5>disabled</h5>

                    <button disabled className="button button--flat">
                        <span>Отправить</span>
                    </button>
                    <br/>
                </div>
            </div>

            <br/>
            <br/>

            <h4>Кнопка по центру</h4>

            <button className="button button--center">
                <span>Отправить</span>
            </button>

            <br/>
            <br/>

            <h4>Кнопка на всю ширину</h4>

            <button className="button button--full">
                <span>Отправить</span>
            </button>

            <br/>
            <br/>


            <h2>Кнопки - ссылки</h2>

            <h4>Обычная кнопка</h4>
            <div className="row">
                <div className="col-12 col-md-4">
                    <h5>Обычное состояние</h5>
                    <a href="#!" className="button">
                        <span>Отправить</span>
                    </a>
                    <br/>
                    <br/>
                </div>
                <div className="col-12 col-md-4">
                    <h5>disabled</h5>

                    <a href="#!" disabled className="button">
                        <span>Отправить</span>
                    </a>
                    <br/>
                    <br/>
                </div>
            </div>

            <br/>

            <h4>Кнопка - flat</h4>
            <div className="row">
                <div className="col-12 col-md-4">
                    <h5>Обычное состояние</h5>
                    <a href="#!" className="button button--flat">
                        <span>Отправить</span>
                    </a>
                    <br/>
                    <br/>
                </div>
                <div className="col-12 col-md-4">
                    <h5>disabled</h5>
                    <a href="#!" disabled className="button button--flat">
                        <span>Отправить</span>
                    </a>
                    <br/>
                    <br/>
                </div>
            </div>

            <br/>
            <br/>

            <h2>Форма</h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <form className="form-test form" id="form-test" encType="multipart/form-data" method="POST" action="/local/static/response/success.json">
                        <div className="form__title">Заголовок тестовой формы</div>
                        <div className="form__success">
                            <div className="success">
                                <div className="success__icon">
                                    <img src="/local/static/build/img/svg/form-success.svg" alt=""/>
                                </div>
                                <div className="success__title">Тестовая форма успешно отправлена!</div>
                            </div>
                        </div>
                        <div className="form__wrapper">
                            <label className="form__label">
                                <span className="form__name">ФИО</span>
                                <span className="form__input">
                                    <input
                                        placeholder="ФИО"
                                        name="name"
                                        type="text"
                                        value=""
                                        required
                                        data-validation-error-msg-required="Поле, обязательно к заполнению"
                                        data-validation="required"
                                    />
                                </span>
                            </label>
                            <label className="form__label">
                                <span className="form__name">Телефон</span>
                                <span className="form__input">
                                    <input
                                        className="js-phone-mask"
                                        placeholder="Телефон"
                                        type="tel"
                                        name="phone"
                                        data-validation-regexp="(\+?\d[- .]*){7,13}"
                                        data-validation="required custom"
                                        data-validation-error-msg-custom="Укажите номер телефона"
                                        data-validation-error-msg-required="Укажите номер телефона"
                                    />
                                </span>
                            </label>
                            <label className="form__label">
                                <span className="form__name">E-mail</span>
                                <span className="form__input">
                                    <input
                                        name="email"
                                        type="email"
                                        value=""
                                        placeholder="E-mail"
                                        data-validation-error-msg-email="Вы задали некорректный e-mail"
                                        data-validation="email"
                                    />
                                </span>
                            </label>
                            <label className="form__label">
                                <span className="form__name">Текстовое поле</span>
                                <span className="form__input">
                                    <textarea name="text" rows="4"></textarea>
                                </span>
                            </label>

                            <div className="form__label form__checkboxs">
                                <label className="form__checkbox">
                                    <input
                                        type="checkbox"
                                        name="agreement"
                                        required
                                        data-validation-error-msg-required="Поле, обязательно к заполнению"
                                        data-validation="required"
                                    />
                                    <i></i>
                                    <span className="form__checkbox-name">Чекбокс 1</span>
                                </label>
                            </div>
                            <div className="form__label form__checkboxs">
                                <label className="form__checkbox">
                                    <input
                                        type="checkbox"
                                        name="agreement"
                                        required
                                        data-validation-error-msg-required="Поле, обязательно к заполнению"
                                        data-validation="required"
                                    />
                                    <i></i>
                                    <span className="form__checkbox-name">Чекбокс 2</span>
                                </label>
                            </div>

                            <div className="form__inner-wrapper">
                                <div className="form__label form__radios">
                                    <label className="form__radio">
                                        <input
                                            type="radio"
                                            name="agreement"
                                            required
                                            data-validation-error-msg-required="Поле, обязательно к заполнению"
                                            data-validation="required"
                                        />
                                        <i></i>
                                        <span className="form__radio-name">Радиокнопка 1</span>
                                    </label>
                                </div>

                                <div className="form__label form__radios">
                                    <label className="form__radio">
                                        <input
                                            type="radio"
                                            name="agreement"
                                            required
                                            data-validation-error-msg-required="Поле, обязательно к заполнению"
                                            data-validation="required"
                                        />
                                        <i></i>
                                        <span className="form__radio-name">Радиокнопка 2</span>
                                    </label>
                                </div>
                            </div>

                            <div className="form__text">
                                Нажимая кнопку Отправить, я&nbsp;выражаю согласие
                                на&nbsp;обработку персональных данных
                            </div>
                            <button className="button button--center" type="submit"><span>Отправить</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <Link to="/">Go back to the homepage</Link>
    </Layout>
)

export default SecondPage
